<template>

    <div class="container-lg mb-3">

        <div class="header text-center text-md-start">
            <h3>
                <i class="bi bi-receipt"></i>
                {{ $t('titles.invoiceList') }}
            </h3>
            <p class="week-date">
                {{ $t('dateTime.Month') }} : {{ getMonthName(getMonthNumber(state.date)) }} {{ state.date.getFullYear() }}
                <span class="date-long">({{ useDateTimeUtils.showDate(useDateTimeUtils.getFirstDayOfTheMonth(state.date)) }} {{ $t('dateTime.to') }} {{ useDateTimeUtils.showDate(useDateTimeUtils.getLastDayOfTheMonth(state.date)) }})</span>
            </p>
        </div>


        <div v-if="state.loading"
            class="text-center"
        >
            <!--
            <div class="spinner-border" role="status">
                <span class="visually-hidden">Loading...</span>
            </div>
            -->
            <div class="spinner-border text-primary" role="status">
                <span class="visually-hidden">Loading...</span>
            </div>
        </div>


        <div class="row align-items-center date-controls">

            <div class="col-5 col-sm-4">
            <!--
                <input type="radio" class="btn-check" name="options" autocomplete="off" id="fiveDays" :value="parseInt(5)" v-model="state.weekdaysToDisplay" >
                <label class="btn btn-outline-secondary nb-days me-1" for="fiveDays">5 {{ $t('dateTime.days') }}</label>

                <input type="radio" class="btn-check" name="options" autocomplete="off" id="sevenDays" :value="parseInt(7)" v-model="state.weekdaysToDisplay">
                <label class="btn btn-outline-secondary nb-days" for="sevenDays">7 {{ $t('dateTime.days') }}</label>
            -->
            </div>

            <div class="col-4 d-none d-sm-block">
                <Datepicker
                    v-model="state.date"
                    :previewFormat="format"
                    @update:modelValue="updateDate"
                    :enableTimePicker="false"
                    locale="fr"
                    :format="format"
                    name="date-picker"

                ></Datepicker>
            </div>

            <div class="col-7 col-sm-4 text-end">
                <button
                    class="btn btn-primary me-2"
                    @click.prevent="setToday()"
                >
                    {{ $t('dateTime.Today') }}
                </button>

                <button
                    class="btn btn-secondary prev-next me-1"
                    @click.prevent="previousMonth()"
                >
                    <i class="bi bi-chevron-left"></i>
                </button>
                <button
                    class="btn btn-secondary prev-next"
                    @click.prevent="nextMonth()"
                >
                    <i class="bi bi-chevron-right"></i>
                </button>
            </div>
        </div>



        <!-- ******************************************************** -->
        <!-- Filter on customers                                      -->
        <!-- ******************************************************** -->

        <div class="row align-items-center date-controls">
            <span>{{ $t('form.actions.filtersOn') }}</span>

            <div
                v-if="state.filter.id === null"
                class="form-floating mb-3"
            >

                <input
                    id="customerFilter-empty"
                    type="text"
                    class="form-control rounded-4"
                    v-bind:class="formUtilsComp.isFieldEmpty(state.filter.id) ? 'is-invalid' : 'is-valid'"
                    v-model="state.filter.search"
                    @input="onFilterChange()"
                />
                <ul
                    class="list-group"
                    v-show="state.filter.isOpen"
                >
                    <li
                        class="list-group-item"
                        v-for="(result) in state.filter.customers"
                        :key="result.id"
                        @click="setFilterCustomerResult(result)"
                        @mouseover="mouseOver(result.id)"
                        @mouseout="mouseOut()"
                        v-bind:class="isMouseOvering(result.id) ? 'active' : ''"
                    >
                        id: {{ result.id }} -- {{ result.firstname }} {{ result.lastname }}, {{ result.postcode }} {{ result.city }}
                    </li>
                </ul>

                <label for="customerFilter-empty">
                    {{ $t('models.customer.customer') }}
                </label>

            </div>

            <div
                v-else
                class="form-floating mb-3"
            >
                <div class="input-group mb-3">
                    <input
                        type="text"
                        class="form-control"
                        v-bind:class="formUtilsComp.isFieldEmpty(state.filter.id) ? 'is-invalid' : 'is-valid'"
                        placeholder="Test field"
                        aria-label="Test field"
                        aria-describedby="button-addon3"
                        v-model="state.filter.search"
                        disabled
                    >
                    <button
                        class="btn btn-outline-secondary"
                        type="button"
                        id="button-addon3"
                        @click="resetFilterSearchedCustomer()"
                    >X</button>
                </div>
            </div>
        </div>


        <!-- ******************************************************** -->
        <!-- Fileter on open or invoiced Invoices                     -->
        <!-- ******************************************************** -->

        <div class="row">
            <div class="col align-self-start">
                <div class="ms-3 my-3">
                    <div>
                        <label class="form-check-label" for="filters">{{ $t('form.actions.filtersOn') }} :</label>
                    </div>
                    <div class="form-check form-check-inline">
                        <input v-model.number="state.checkedInvoices" class="form-check-input" type="checkbox" :value="parseInt(0)">
                        <label class="form-check-label" for="active">{{ $t('models.invoice.statusFilter.open') }}</label>
                    </div>
                    <div class="form-check form-check-inline">
                        <input v-model.number="state.checkedInvoices" class="form-check-input" type="checkbox" :value="parseInt(1)">
                        <label class="form-check-label" for="inactive">{{ $t('models.invoice.statusFilter.invoiced') }}</label>
                    </div>
                    <div class="form-check form-check-inline">
                        <input v-model.number="state.checkedInvoices" class="form-check-input" type="checkbox" :value="parseInt(2)">
                        <label class="form-check-label" for="inactive">{{ $t('models.invoice.statusFilter.printed') }}</label>
                    </div>
                </div>
            </div>
        </div>


        <!-- ******************************************************** -->
        <!-- Alert if no Meals registered in the current month        -->
        <!-- ******************************************************** -->

        <div v-if="state.mealsArrayEmpty" class="alert alert-primary" role="alert">
            {{ $t('alerts.invoices.noMeals') }}
        </div>

        <div v-if="state.customersToDisplay.length === 0 && !state.mealsArrayEmpty" class="alert alert-warning" role="alert">
            {{ $t('alerts.invoices.noInvoiceWithSelection') }}
        </div>



        <!-- ******************************************************** -->
        <!-- Display Invoices list                                    -->
        <!-- ******************************************************** -->


        <div
            class="row my-5"
            v-if="useCustomers.state.form.resources !== undefined"
        >

            <template
                v-for="customer in state.customersToDisplay"
                v-bind:key="customer"
            >

                <div
                    class="card mb-3"
                    v-bind:class="getInvoiceFromCustomerId(customer.id) !== null ? 'border-success' : 'border-danger'"
                    v-if="(state.filter.id === null) || ((customer.id === state.filter.id))"
                >

                    <div
                        v-if="getInvoiceFromCustomerId(customer.id) !== null"
                        class="card-header text-white bg-success fs-5"
                    >
                        {{ $t('titles.invoiceNumber') }} : {{ getInvoiceFromCustomerId(customer.id).id }}
                    </div>

                    <div
                        v-else
                        class="card-header text-white bg-danger fs-5"
                    >
                        {{ $t('titles.invoiceNumber') }} : ---
                    </div>

                    <div class="card-body">

                        <div class="row">

                            <div class="col">
                                <h5 class="card-title">
                                    {{ customer.lastname }} {{ customer.firstname }}
                                </h5>
                                <h6 class="card-subtitle mb-2">
                                    {{ customer.street }} {{ customer.number }}
                                </h6>
                                <h6 class="card-subtitle mb-3">
                                    {{ customer.postcode }} {{ customer.city }}
                                </h6>
                                <p class="text">
                                    {{ $t('models.invoice.status') }} : {{ getInvoiceFromCustomerId(customer.id).status }}
                                </p>
                            </div>

                            <div class="col">
                                <div class="d-flex justify-content-center">
                                    <div v-show="useMeals.state.form.loading" class="spinner-border text-primary" role="status">
                                        <span class="visually-hidden">Loading...</span>
                                    </div>
                                </div>
                            </div>


                            <div v-if="getInvoiceFromCustomerId(customer.id) === null" class="col">
                                <div class="justify-content-end">
                                    <a
                                        href="#"
                                        @click.prevent="generateInvoice(customer.id)"
                                        class="btn btn-warning btn-sm mx-3"
                                        :target="_blank">
                                        {{ $t('models.invoice.generateInvoice') }}
                                    </a>
                                </div>
                            </div>

                            <div v-else class="col">
                                <div class="justify-content-end">
                                    <a
                                        href="#"
                                        @click.prevent="getInvoicePdf(customer, getInvoiceFromCustomerId(customer.id))"
                                        class="btn btn-info btn-sm mx-3"
                                        :target="_blank">
                                        {{ $t('form.actions.printPdf') }}
                                    </a>
                                </div>
                            </div>

                            <div v-if="getInvoiceFromCustomerId(customer.id) !== null" class="col">
                                <div class="justify-content-end">
                                    <a
                                        href="#"
                                        @click.prevent="deleteInvoice(getInvoiceFromCustomerId(customer.id), getMealsFilteredOnCustomer(customer.id))"
                                        class="btn btn-outline-danger btn-sm mx-3"
                                        :target="_blank">

                                        <i class="bi bi-trash"></i>
                                        {{ $t('models.invoice.deleteInvoice') }}
                                    </a>
                                </div>
                            </div>

                        </div>  <!--  class="row"  -->



                        <div class="table-responsive">
                            <table class="table table-bordered table-striped table-hover table-sm">
                                <thead class="table-dark">
                                    <tr>
                                        <th scope="col">id</th>
                                        <th scope="col">{{ $t('models.meal.date') }}</th>
                                        <th scope="col">{{ $t('models.meal.invoice_id') }}</th>

                                        <th scope="col">{{ $t('models.meal.portions') }}</th>
                                        <th scope="col">Price</th>
                                        <th scope="col">{{ $t('models.meal.half_portions') }}</th>
                                        <th scope="col">Price</th>
                                        <th scope="col">{{ $t('models.meal.amount') }}</th>
                                        <template v-if="masterUser()">
                                            <th scope="col">{{ $t('form.actions.actions') }}</th>
                                        </template>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr
                                        v-for="meal in getMealsFilteredOnCustomer(customer.id)"
                                        v-bind:key="meal"
                                    >
                                        <td>{{ meal.id }}</td>
                                        <td>{{ formatedDate(meal.date) }}</td>



                                        <template v-if="getInvoiceFromCustomerId(customer.id) === null">
                                            <td
                                                v-if="meal.invoice_id === null"
                                            >
                                                null
                                            </td>
                                            <td
                                                v-else
                                                v-bind:class="'bg-danger text-white'"
                                            >
                                                {{ meal.invoice_id }}
                                                <a
                                                    class="icon-edit ms-5"
                                                    href="#"

                                                    @click.prevent="setInvoiceIdToMeal(meal, null)"
                                                >
                                                    <i class="bi bi-arrow-up-square-fill"></i>
                                                </a>
                                            </td>
                                        </template>

                                        <template v-else>
                                            <td
                                                v-if="meal.invoice_id === null"
                                                v-bind:class="getInvoiceFromCustomerId(customer.id) !== null ? 'bg-danger text-white' : ''"
                                            >
                                                null
                                                <a
                                                    class="icon-edit ms-5"
                                                    href="#"

                                                    @click.prevent="setInvoiceIdToMeal(meal, getInvoiceFromCustomerId(customer.id).id)"
                                                >
                                                    <i class="bi bi-arrow-up-square-fill"></i>
                                                </a>
                                            </td>


                                            <td
                                                v-else-if="getInvoiceFromCustomerId(customer.id).id !== meal.invoice_id"
                                                v-bind:class="getInvoiceFromCustomerId(customer.id).id !== meal.invoice_id ? 'bg-danger text-white' : ''"
                                            >
                                                {{ meal.invoice_id }}
                                                <a
                                                    class="icon-edit ms-5"
                                                    href="#"

                                                    @click.prevent="setInvoiceIdToMeal(meal, getInvoiceFromCustomerId(customer.id).id)"
                                                >
                                                    <i class="bi bi-arrow-up-square-fill"></i>
                                                </a>
                                            </td>



                                            <td
                                                v-else
                                                v-bind:class="getInvoiceFromCustomerId(customer.id).id !== meal.invoice_id ? 'bg-danger text-white' : ''"
                                            >
                                                {{ meal.invoice_id }}
                                            </td>

                                        </template>






                                        <td>{{ meal.portion_qty }}</td>
                                        <td>{{ meal.portion_price }}</td>

                                        <td>{{ meal.half_portion_qty }}</td>
                                        <td>{{ meal.half_portion_price }}</td>

                                        <td>{{ parseFloat((meal.portion_qty * meal.portion_price) + (meal.half_portion_qty * meal.half_portion_price)).toFixed(2) }}</td>
                                        <td v-if="masterUser()">
                                            <a
                                                class="icon-edit"
                                                href="#"
                                                @click.prevent="deleteMeal(meal)"
                                            >
                                                <i class="bi bi-trash"></i>
                                            </a>
                                        </td>
                                    </tr>

                                    <tr class="table-warning">
                                        <td colspan="2">{{ $t('models.invoice.discount') }}</td>

                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>

                                        <td v-if="getInvoiceFromCustomerId(customer.id) === null">
                                            {{ parseFloat(0.00).toFixed(2) }}
                                        </td>
                                        <td v-else>
                                            {{ parseFloat(getInvoiceFromCustomerId(customer.id).discount).toFixed(2) }}
                                        </td>

                                        <td v-if="masterUser()">
                                            <a
                                                v-if="getInvoiceFromCustomerId(customer.id) != null"
                                                class="icon-edit"
                                                href="#"

                                                @click.prevent="editInvoice(getInvoiceFromCustomerId(customer.id))"
                                            >
                                                <i class="bi bi-pencil-fill"></i>
                                            </a>
                                        </td>
                                    </tr>
                                </tbody>

                                <tfoot class="table-danger">
                                    <tr class="fw-bold">
                                        <td colspan="2">{{ $t('models.meal.totals') }}</td>
                                        <td></td>
                                        <td>
                                            {{ getTotalPortions(customer.id).portions_qty }}
                                        </td>
                                        <td></td>
                                        <td>
                                            {{ getTotalPortions(customer.id).half_portion_qty }}
                                        </td>
                                        <td></td>
                                        <td>
                                            {{ parseFloat( getTotalPortions(customer.id).amount ).toFixed(2) }}
                                        </td>
                                        <td v-if="masterUser()"></td>
                                    </tr>
                                </tfoot>
                            </table>
                        </div>

                    </div>
                </div>




            </template>
        </div>



        <!-- ****************************************************** -->
        <!-- Modal form to invoice the order and define discount    -->
        <!-- ****************************************************** -->
        <MyModal
            v-bind:idProps="state.modal.edit"
        >
            <!--    Content for header slot     -->
            <template v-slot:header>
                {{ state.modal.header }}
            </template>

            <template v-slot:body>

                <div class="form-floating mb-3">
                    <input
                        type="number"
                        min="0"
                        step="0.01"
                        class="form-control rounded-4"
                        id="discount"
                        v-bind:class="formUtilsComp.isFieldEmpty(useInvoices.state.form.body.discount) ? 'is-invalid' : 'is-valid'"
                        @keyup.enter="saveInvoice()"
                        placeholder="discount"
                        v-model="useInvoices.state.form.body.discount"
                    >
                    <label for="discount">{{ $t('models.invoice.discount') }} [CHF]</label>
                    <!-- Diplay frontend application alert message -->
                    <div v-if="formUtilsComp.isFieldEmpty(useInvoices.state.form.body.discount)" class="text-info text-sm">
                        {{ $t('models.invoice.validations.discount') }}
                    </div>
                    <!-- Diplay alert message received from backend -->
                    <ValidationErrors v-bind:errors="useInvoices.useErrors.state.errors.discount"></ValidationErrors>
                </div>

                <button
                    @click="saveInvoice()"
                    class="w-100 mb-2 btn btn-lg rounded-4 btn-primary"
                >
                    {{ $t('form.actions.save') }}
                </button>
            </template>
        </MyModal>

    </div>
</template>

<script>

//import { ref } from 'vue
import { reactive } from 'vue'
import { onMounted } from 'vue'
import { computed } from 'vue'

// import external functionalities
import { Modal } from 'bootstrap';

// import application's components
import MyModal from '@/components/MyModal.vue'

import { useI18n } from 'vue-i18n'

// import Datepicker
import Datepicker from 'vue3-date-time-picker';
import 'vue3-date-time-picker/dist/main.css'


// import composables files
import useAuthComp from '@/composables/useAuthComp.js'
import useMealsComp from '@/composables/useMealsComp.js'
import useCustomersComp from '@/composables/useCustomersComp.js'
import useInvoicesComp from '@/composables/useInvoicesComp.js'
import useDateTimeUtilsComp from '@/composables/useDateTimeUtilsComp.js'
import useFormUtilsComp from '@/composables/useFormUtilsComp.js'
import useObjectsUtils from '@/composables/useObjectsUtils.js'
//import useArrayUtilsComp from '@/composables/useArraysUtilsComp.js'

export default {

    name: 'invoices',

    components: {
        Datepicker,
        MyModal,
    },

    setup() {

        const { masterUser } = useAuthComp()

        const useMeals = useMealsComp()
        const useCustomers = useCustomersComp()
        const useInvoices = useInvoicesComp()

        const { t, locale } = useI18n({ useScope: 'global' })
        const useDateTimeUtils = useDateTimeUtilsComp()
        const formUtilsComp = useFormUtilsComp()
        const objUtils = useObjectsUtils()
        //const useArrayUtils = useArrayUtilsComp()

        const state = reactive({
            date: new Date(),
            customers: null,
            loading: false,
            mealsArrayEmpty: false,
            checkedInvoices: [0, 1],

            modal: {
                edit: 'editInvoiceModal',
                show: 'showInvoiceModal',
                changeGroupAlert: 'changeMealGroupAlertModal',
                header: '',
                action: '',
                componentEdit: null,
                componentShow: null,
                componentGroupChangeAlert: null,
                componentAlert: null,
            },

            models: {
                customers: [],
                invoices: [],
            },

            events: {
                mouseOver: '',
            },

            filter: {
                id: null,
                search: '',
                customers: [],
                isOpen: false,
                mouseOver: '',
            },

/*
            customersToDisplay: computed(() => {

               let customersListToDisplay = []

               for (let j = 0; j < state.models.customers.length; j++) {

                   // If open orders checked
                   if ( state.checkedInvoices.includes(0) ) {
                       if ( getInvoiceFromCustomerId(state.models.customers[j].id) === null ) {
                           customersListToDisplay.push(state.models.customers[j])
                       }
                   }

                   // If invoiced orders checked
                   if ( state.checkedInvoices.includes(1) ) {
                       if ( getInvoiceFromCustomerId(state.models.customers[j].id) !== null ) {
                           customersListToDisplay.push(state.models.customers[j])
                       }
                   }

               }

               return customersListToDisplay
           }),
*/


            customersToDisplay: computed(() => {

                let customersListToDisplay = []

                for (let j = 0; j < state.models.customers.length; j++) {

                    //console.log(getInvoiceFromCustomerId(state.models.customers[j].id))

                    // If open orders checked
                    if ( state.checkedInvoices.includes(0) ) {
                        if ( getInvoiceFromCustomerId(state.models.customers[j].id) === null ) {
                            customersListToDisplay.push(state.models.customers[j])
                        }
                    }

                    // If invoiced orders checked
                    if ( state.checkedInvoices.includes(1) ) {
                        if ( getInvoiceFromCustomerId(state.models.customers[j].id) !== null ) {

                        // ***********************************************************
                            if ( getInvoiceFromCustomerId(state.models.customers[j].id).status === 20 ) {
                                customersListToDisplay.push(state.models.customers[j])
                            }
                        // ***********************************************************

                        }
                    }

                    // If invoiced orders checked

                    if ( state.checkedInvoices.includes(2) ) {
                        if ( getInvoiceFromCustomerId(state.models.customers[j].id) !== null ) {

                            // ***********************************************************
                                if ( getInvoiceFromCustomerId(state.models.customers[j].id).status === 30 ) {
                                    customersListToDisplay.push(state.models.customers[j])
                                }
                            // ***********************************************************

                        }
                    }


                }


                console.log(customersListToDisplay)

                return customersListToDisplay
            }),



        })


        onMounted(() => {
            state.loading = true
            getMeals()

            state.modal.componentEdit = new Modal(document.getElementById(state.modal.edit))
            //state.modal.componentGroupChangeAlert = new Modal(document.getElementById(state.modal.changeGroupAlert))
            //state.modal.componentShow = new Modal(document.getElementById(state.modal.show))
        })


        function onFilterChange() {
            onChange(state.filter)
        }

        function onChange(obj) {

            if (obj.search.length >= 2) {

                useCustomers.searchResources(obj.search)
                .then((res) =>{
                    mapCustomers(obj, res)
                })

                obj.isOpen = true
            } else {
                obj.isOpen = false
            }
        }


        function mapCustomers(obj, dataArray) {

            obj.customers = []

            for (var i = 0; i < dataArray.length; i++) {
                let dataObj = {}
                dataObj.id = dataArray[i].id
                dataObj.lastname = dataArray[i].lastname
                dataObj.firstname = dataArray[i].firstname
                dataObj.postcode = dataArray[i].postcode
                dataObj.city = dataArray[i].city
                obj.customers.push(dataObj)
            }
        }

        function setFilterCustomerResult(result) {
            setCustomerResult(state.filter, result)
        }

        function setCustomerResult(obj, result) {
            obj.id = result.id
            obj.search = result.firstname + ' ' + result.lastname
            obj.isOpen = false
        }

        function resetFilterSearchedCustomer() {
            resetSearchedCustomer(state.filter)
        }


        function resetSearchedCustomer(obj) {
            obj.id = null
            obj.search = ''
        }

        function mouseOver(id) {
            state.events.mouseOver = id
        }

        function mouseOut() {
            state.events.mouseOver = ''
        }

        function isMouseOvering(id) {
            return (state.events.mouseOver === id) ? true : false
        }


        /**
         * Test function to print complete pdf
         * @param  {[type]} customer               [description]
         * @return {[type]}          [description]
         */
        function getInvoiceTestPdf(customer) {

            useMeals.getMealsInvoiceTestPdf(
                customer.id,
                useDateTimeUtils.getFirstDayOfTheMonth(state.date),
                useDateTimeUtils.getLastDayOfTheMonth(state.date)
            )
        }


        function getInvoicePdf(customer, invoice) {

            console.log("status :")
            console.log(invoice.status)

            useMeals.getMealsInvoicePdf(
                customer,
                invoice,
            ).then(() => {
                invoice.status = invoice.status + 10

                console.log("status :")
                console.log(invoice.status)
            })


        }


        function getMeals() {

            state.loading = true

            useMeals.getMealsFromDateInterval({
                start: useDateTimeUtils.formatIsoDate(useDateTimeUtils.getFirstDayOfTheMonth(state.date)),
                end: useDateTimeUtils.formatIsoDate(useDateTimeUtils.getLastDayOfTheMonth(state.date))
            })
            .then(() => {

                if (useMeals.state.form.resources.length > 0) {
                    state.mealsArrayEmpty = false
                    getCustomers(useMeals.state.form.resources)
                    getInvoices(useMeals.state.form.resources)
                } else {
                    state.mealsArrayEmpty = true
                    state.models.customers = []
                    state.models.invoices = []
                }
            })
            .then(() => {
                state.loading = false
            })
        }

        /**
         * [getInvoices description]
         * @param  {[type]} arr [array of Meals]
         * @return {[type]}     [description]
         */
        function getInvoices(arr) {

            let invoices = []

            for (let i = 0; i < arr.length; i++) {
                if ( invoices.indexOf(arr[i].invoice_id) === -1 ) {
                    // null has to be pushed
                    if (arr[i].invoice_id !== null) {
                        invoices.push(arr[i].invoice_id)
                    }
                }
            }

            if (invoices.length > 0) {
                useInvoices.getInvoicesFromArrayId(invoices)
                .then((res) => {
                    // state.loading = false
                    // Clone user's attributes
                    //state.models.invoices = objUtils.cloneObject(useInvoices.state.form.resources)
                    state.models.invoices = objUtils.cloneObject(res)
                    //useInvoices.state.form.resources = res
                })
            }
        }


        /**
         * [getCustomers description]
         * @param  {[type]} arr [array of Meals]
         * @return {[type]}     [description]
         */
        function getCustomers(arr) {

            let customers = []

            for (let i = 0; i < arr.length; i++) {
                if ( customers.indexOf(arr[i].customer_id) === -1 ) {
                    // null has to be pushed
                    if (arr[i].customer_id !== null) {
                        customers.push(arr[i].customer_id)
                    }
                }
            }

            if (customers.length > 0) {
                useCustomers.getCustomersFromArrayId(customers)
                .then(() => {
                    // state.loading = false
                    // Clone user's attributes
                    state.models.customers = objUtils.cloneObject(useCustomers.state.form.resources)
                })
            }

        }



        function setInvoiceIdToMeal(meal, invoiceId) {
            useMeals.state.form.body = objUtils.cloneObject(meal)
            useMeals.state.form.body.group_id = null   // update only one Meal
            useMeals.state.form.body.update_all = null   // update only one Meal
            useMeals.state.form.body.invoice_id = invoiceId   // update only one Meal


            useMeals.updateResource(
                {
                    start: useDateTimeUtils.formatIsoDate(useDateTimeUtils.getFirstDayOfTheMonth(state.date)),
                    end: useDateTimeUtils.formatIsoDate(useDateTimeUtils.getLastDayOfTheMonth(state.date))
                }
            )
            .then(() => {
                useMeals.flushAttributes()
            })
            .catch((error) => {
                useMeals.useErrors.useSetErrors(error.response.data)
            })

        }

        function compare( a, b ) {

            if ( a.date < b.date ) {
                return -1;
            }
            if ( a.date > b.date ) {
                return 1;
            }
            return 0;
        }


        function getInvoiceFromCustomerId(customer_id) {

/*
            console.log(customer_id)

            console.log('getInvoiceFromCustomerId')
            console.log('customer id : ' + customer_id)
            console.log('state.models.invoices : ')
            console.log(state.models.invoices)
*/

            let invoice = state.models.invoices.find(x => x.customer_id === customer_id)
            //let invoice = useInvoices.state.form.resources.find(x => x.customer_id === customer_id)

            //console.log(invoice)


            if (invoice === undefined) {
                return null
            } else {
                return invoice
            }
        }


        function getMealsFilteredOnCustomer(customer_id) {

            let meals = useMeals.state.form.resources.filter(meal => meal.customer_id === customer_id)
            let sortedMeals = meals.sort( compare )

            return sortedMeals
        }


        function getTotalPortions(customer_id) {

            let meals = getMealsFilteredOnCustomer(customer_id)

            let total = {
                portions_qty: 0,
                half_portion_qty: 0,
                amount: 0,
            }

            for ( let i = 0; i < meals.length; i++ ) {
                total.portions_qty = total.portions_qty + meals[i].portion_qty
                total.half_portion_qty = total.half_portion_qty + meals[i].half_portion_qty
                total.amount = total.amount + (meals[i].portion_qty * meals[i].portion_price) + (meals[i].half_portion_qty * meals[i].half_portion_price)
            }

            // If invoice existing, substract the discount
            if (getInvoiceFromCustomerId(customer_id) !== null) {
                total.amount = total.amount - getInvoiceFromCustomerId(customer_id).discount
            }

            return total
        }

        function editInvoice(invoice) {
            //console.log(parseFloat(invoice.discount))
            //console.log(typeof parseFloat(invoice.discount))

            // Define modal attributes
            state.modal.header = t('form.cards.invoices.headers.edit')
            state.modal.action = 'edit'

            // Open Modal component
            state.modal.componentEdit.toggle()

            // Erase error messages
            useInvoices.useErrors.useResetErrors()

            // Clone user's attributes
            //useInvoices.state.form.body = objUtils.cloneObject(invoice)
            useInvoices.state.form.body.id = invoice.id
            useInvoices.state.form.body.discount = invoice.discount
        }

        function generateInvoice(customer_id) {

            // Define modal attributes
            state.modal.header = t('form.cards.invoices.headers.create')
            state.modal.action = 'create'

            // Open Modal component
            state.modal.componentEdit.toggle()

            // Erase error messages
            useInvoices.useErrors.useResetErrors()

            // Flush user's attributes
            useInvoices.flushAttributes()
            useInvoices.state.form.body.customer_id = customer_id

        }

        function saveInvoice() {

            if ( useInvoices.state.form.body.id == null ) {
                storeInvoice()
            } else {
                updateInvoice()
            }

        }


        function invoiceStateUpdate(invoice) {

            let changedInvoice = invoice

            //let indexInvoice = useInvoices.state.form.resources.findIndex(x => x.id === createdInvoice.id)
            let indexInvoice = state.models.invoices.findIndex(x => x.id === changedInvoice.id)

            // if resource not existing in array
            if (indexInvoice < 0) {
                // add resource to array
                //useInvoices.state.form.resources.push(createdInvoice)
                state.models.invoices.push(changedInvoice)
            } else {
                // update resource in array
                //useInvoices.state.form.resources.splice(indexInvoice, 1, createdInvoice)
                state.models.invoices.splice(indexInvoice, 1, changedInvoice)
            }

        }

        function updateInvoice() {

            useInvoices.updateResource()
            .then((res) => {

                // Update state
            /*
                let updatedInvoice = res.data.resource
                let indexInvoice = state.models.invoices.findIndex(x => x.id === updatedInvoice.id)
                state.models.invoices.splice(indexInvoice, 1, updatedInvoice)
            */

                invoiceStateUpdate(res.data.resource)

                // Close Modal component
                state.modal.componentEdit.toggle()
            })
            .catch((error) => {
                //formUtilsComp.useSetErrors(error.response.data)
                useInvoices.useErrors.useSetErrors(error.response.data)
            })
        }


        function storeInvoice() {

            let customerId = useInvoices.state.form.body.customer_id
            let meals = getMealsFilteredOnCustomer(customerId)
            let discount = useInvoices.state.form.body.discount

            let arrayMealsId = []

            for (let i = 0; i < meals.length; i++) {
                arrayMealsId.push(meals[i].id)
            }

            let data = {
                arrayMealsId: arrayMealsId,
                discount: discount,
            }

            useInvoices.storeFromCustomerId(customerId, data)
            .then((res) => {

                // Update state
                invoiceStateUpdate(res.data.invoice)


/*
                let createdInvoice = res.data.invoice

                //let indexInvoice = useInvoices.state.form.resources.findIndex(x => x.id === createdInvoice.id)
                let indexInvoice = state.models.invoices.findIndex(x => x.id === createdInvoice.id)

                // if resource not existing in array
                if (indexInvoice < 0) {
                    // add resource to array
                    //useInvoices.state.form.resources.push(createdInvoice)
                    state.models.invoices.push(createdInvoice)
                } else {
                    // update resource in array
                    //useInvoices.state.form.resources.splice(indexInvoice, 1, createdInvoice)
                    state.models.invoices.splice(indexInvoice, 1, createdInvoice)
                }
*/

                //state.models.invoices.push(res)


                let updatedMeals = res.data.meals

                for (var i = 0; i < updatedMeals.length; i++) {

                    let indexMeal = useMeals.state.form.resources.findIndex(x => x.id === updatedMeals[i].id)

                    // if resource not existing in array
                    if (indexMeal < 0) {
                        // add resource to array
                        useMeals.state.form.resources.push(updatedMeals[i])
                    } else {
                        // update resource in array
                        useMeals.state.form.resources.splice(indexMeal, 1, updatedMeals[i])
                    }
                }


                // Close Modal component
                state.modal.componentEdit.toggle()

            })
        }




        function stringifyJson(arr) {
            return JSON.stringify(arr)
        }

        function deleteMeal(resource) {

            if ( confirm(t('form.infos.delete.confirmation') + ` '${resource.id} ${resource.date}'`) ) {
                useMeals.deleteResource(resource.id)
            }
        }


        function deleteInvoice(invoice, meals) {
            if ( confirm(t('form.infos.delete.confirmation') + ` '${invoice.id} ${invoice.date}'`) ) {
                useInvoices.deleteResource(invoice.id)
                .then(() => {

                    for (let i = 0; i < meals.length; i++) {
                        meals[i].invoice_id = null
                    }

                    let index = state.models.invoices.findIndex(x => x.id === invoice.id)
                    state.models.invoices.splice(index, 1)

                })
            }
        }



        function getMonthNumber(date) {
            let month = useDateTimeUtils.getMonthNumber(date)
            return month
        }

        function getMonthName(n) {
            let months = [
                t('dateTime.monthsLong.january'),
                t('dateTime.monthsLong.february'),
                t('dateTime.monthsLong.march'),
                t('dateTime.monthsLong.april'),
                t('dateTime.monthsLong.may'),
                t('dateTime.monthsLong.june'),
                t('dateTime.monthsLong.july'),
                t('dateTime.monthsLong.august'),
                t('dateTime.monthsLong.september'),
                t('dateTime.monthsLong.october'),
                t('dateTime.monthsLong.november'),
                t('dateTime.monthsLong.december'),
            ]
            return months[n]
        }

        function nextMonth() {
            state.date = useDateTimeUtils.addMonths(state.date, 1)
            getMeals()
        }

        function previousMonth() {
            state.date = useDateTimeUtils.substractMonths(state.date, 1)
            getMeals()
        }

        function setToday() {
            state.date = new Date()
            getMeals()
        }

        function updateDate() {
            getMeals()
        }

        // Return formated date to display
        function formatedDate(date) {
            return useDateTimeUtils.formatedDate(new Date(date), locale.value, "dddd D.M.YYYY")
        }


        return {
            useMeals,
            useCustomers,
            useInvoices,
            state,
            useDateTimeUtils,
            getMonthNumber,
            getMonthName,
            previousMonth,
            nextMonth,
            setToday,
            updateDate,
            formatedDate,
            stringifyJson,
            //getMealsOrderedByCustomers,
            getCustomers,
            getMealsFilteredOnCustomer,
            getInvoiceFromCustomerId,
            getTotalPortions,
            masterUser,
            deleteMeal,
            mouseOver,
            mouseOut,
            isMouseOvering,
            onFilterChange,
            setFilterCustomerResult,
            resetFilterSearchedCustomer,
            formUtilsComp,
            getInvoiceTestPdf,
            getInvoicePdf,
            generateInvoice,
            saveInvoice,
            deleteInvoice,
            editInvoice,
            setInvoiceIdToMeal,
        }

    },
}

</script>

<style>
    @import '../../assets/css/logo.css';
    @import '../../assets/css/meals.css';
</style>
